export class Customer {
    constructor(
        customerNumber: number,
        currency: string,
        //PaymentTerms PaymentTerms
        //CustomerGroup CustomerGroup
        address: string,
        balance: number,
        dueAmount: number,
        corporateIdentificationNumber: string,
        city: string,
        country: string,
        email: string,
        name: string,
        zip: string,
        //VatZone VatZone
        lastUpdated: Date,
        contacts: string,
        //Templates Templates
        //Totals Totals
        deliveryLocations: string,
        //Invoices Invoices
        eInvoicingDisabledByDefault: boolean,
        self: string,
        creditLimit: number | null,
        telephoneAndFaxNumber: string,
        website: string,
        //Layout Layout
        mobilePhone: string,
        publicEntryNumber: string
        //SalesPerson SalesPerson
        ) {
            this.customerNumber = customerNumber;
            this.currency = currency;
            //PaymentTerms PaymentTerms
            //CustomerGroup CustomerGroup
            this.address = address;
            this.balance = balance;
            this.dueAmount = dueAmount
            this.corporateIdentificationNumber = corporateIdentificationNumber;
            this.city = city;
            this.country = country;
            this.email = email;
            this.name = name;
            this.zip = zip;
            //VatZone VatZone
            this.lastUpdated = lastUpdated;
            this.contacts = contacts;
            //Templates Templates
            //Totals Totals
            this.deliveryLocations = deliveryLocations;
            //Invoices Invoices
            this.eInvoicingDisabledByDefault= eInvoicingDisabledByDefault;
            this.self = self;
            this.creditLimit = creditLimit;
            this.telephoneAndFaxNumber= telephoneAndFaxNumber;
            this.website = website;
            //Layout Layout
            this.mobilePhone = mobilePhone;
            this.publicEntryNumber= publicEntryNumber;
            //SalesPerson SalesPerson
            this.landkodeAlfa = null;
            this.landkodeNumerisk = null;        
           }
    customerNumber: number;
    currency: string;
    //PaymentTerms PaymentTerms
    //CustomerGroup CustomerGroup
    address: string;
    balance: number;
    dueAmount: number;
    corporateIdentificationNumber: string;
    city: string;
    country: string;
    email: string;
    name: string;
    zip: string;
    //VatZone VatZone
    lastUpdated: Date;
    contacts: string;
    //Templates Templates
    //Totals Totals
    deliveryLocations: string;
    //Invoices Invoices
    eInvoicingDisabledByDefault: boolean;
    self: string;
    creditLimit: number | null;
    telephoneAndFaxNumber: string;
    website: string;
    //Layout Layout
    mobilePhone: string;
    publicEntryNumber: string;
    //SalesPerson SalesPerson
    landkodeAlfa: string | null;
    landkodeNumerisk: string | null;

}
