import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonsWrapper = styled.div`
  color: #e65100;
  font-weight: 400;
  font-size: 0.9rem;
  padding: 2px;
  position: relative;
`;

export const Buttons2Wrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin: 4px 6px 0 5px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin: 4px 6px 0 500px;
`;

export const DateWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin: 6px 6px 0 5px;
`;

export interface IButtonProps {
    isSelected?: boolean;
    altColor?: boolean;
    onClick?: (eventTypeId: number) => void;
}

export const GetButton = styled.button<IButtonProps>`
  font-size: 0.9rem;
  color: #04265a;
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 3px 10px;
  margin: 4px 6px 0 5px;
  
  ${({isSelected }) =>
    isSelected &&
    css`
      color: #04265a;
      background: #fff;
      border: 1px solid #ccc;
   `}
`;

