import React, {useContext, useEffect} from 'react';
import { EconomyDataContext } from '../context/EconomyDataContext';
import { InvoiceDataFactory } from '../factory/InvoiceDataFactory';
import { InvoiceDataBM } from '../types/BusinessModel/InvoiceDataBM';
import useApi from './useApi';
import moment from 'moment';
import { AuthContext } from '../context/AuthContext';

export default () => {
    const invoiceDataFactory = new InvoiceDataFactory();

    const endpoint = 'GetInvoices';
    const { state, getApiData } = useApi();
    const {
        setIsLoadingInvoiceData,
        setInvoiceData,
    } = useContext(EconomyDataContext)!;
    const { loggedInUser } = React.useContext(AuthContext);

    const dateToQueryParam = (date: Date): string => {
        return moment(date).format('YYYY-MM-DD')
    }

    const getInvoices = (fromDate: Date, toDate: Date) => {
            const queryString = '?fromDate=' + dateToQueryParam(fromDate) + '&toDate=' + dateToQueryParam(toDate) + '&skipPages=0';
            getApiData(endpoint, queryString);
    };

    useEffect(() => {
        setIsLoadingInvoiceData(state.isLoading);
        if (state.status === 'success'){
            const invoiceDataBM = state.result as unknown as InvoiceDataBM;
            const invoiceData = invoiceDataFactory.newInvoiceData(invoiceDataBM);
            invoiceData.dataReceived = true;
            setInvoiceData(invoiceData);
        } else if (state.status === 'error') {
            setInvoiceData(invoiceDataFactory.newEmptyInvoiceDataFromCode());
        }
    }, [state]);

    return { state, getInvoices };
}
