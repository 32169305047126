import React, {useContext, useEffect} from 'react';
import { AuthContext } from '../context/AuthContext';
import { EconomyDataContext } from '../context/EconomyDataContext';
import { CustomerDataFactory } from '../factory/CustomerDataFactory';
import { CustomerDataBM } from '../types/BusinessModel/CustomerDataBM';
import useApi from './useApi';

export default () => {
    const customerDataFactory = new CustomerDataFactory();

    const endpoint = 'GetCustomers';
    const { state, getApiData } = useApi();
    const {
        setIsLoadingCustomerData,
        setCustomerData
    } = useContext(EconomyDataContext)!;
    const { loggedInUser } = React.useContext(AuthContext);

    const getCustomers = (customerIdList: string) => {
        if (customerIdList.length > 0) {
            const queryString = '?customerIdList=' + customerIdList;
            getApiData(endpoint, queryString);
        }
    };

    useEffect(() => {
        setIsLoadingCustomerData(state.isLoading);
        if (state.status === 'success'){
            const customerDataBM = state.result as unknown as CustomerDataBM;
            const customerData = customerDataFactory.newCustomerData(customerDataBM);
            setCustomerData(customerData);
        }
    }, [state]);

    return { state, getCustomers };
}
