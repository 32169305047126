import { CustomerBM } from '../types/BusinessModel/CustomerBM';
import { CustomerDataBM } from '../types/BusinessModel/CustomerDataBM';
import { Customer } from '../types/ViewModel/Customer';
import { CustomerData } from '../types/ViewModel/CustomerData';

export class CustomerFactory {
    constructor() {}

    public newCustomerArray(customerBMArray: CustomerBM[]): Customer[] {
        var customerArray: Customer[] = [];
        if (customerBMArray && customerBMArray.length > 0) {
            customerBMArray.forEach(cust => {
                customerArray.push(this.newCustomer(cust));
            });
        }
        return customerArray;
    }

    public newCustomerArrayFromCode(customerArray: Customer[]): Customer[] {
        var custArray: Customer[] = [];
        if (customerArray && customerArray.length > 0) {
            customerArray.forEach(cust => {
                custArray.push(this.newCustomerFromCode(cust));
            });
        }
        return custArray;
    }

    public newCustomer(customerBM: CustomerBM): Customer {
        return new Customer(
            customerBM.customerNumber,
            customerBM.currency,
            //PaymentTerms PaymentTerms
            //CustomerGroup CustomerGroup
            customerBM.address,
            customerBM.balance,
            customerBM.dueAmount,
            customerBM.corporateIdentificationNumber,
            customerBM.city,
            customerBM.country,
            customerBM.email,
            customerBM.name,
            customerBM.zip,
            //VatZone VatZone
            customerBM.lastUpdated,
            customerBM.contacts,
            //Templates Templates
            //Totals Totals
            customerBM.deliveryLocations,
            //Invoices Invoices
            customerBM.eInvoicingDisabledByDefault,
            customerBM.self,
            customerBM.creditLimit,
            customerBM.telephoneAndFaxNumber,
            customerBM.website,
            //Layout Layout
            customerBM.mobilePhone,
            customerBM.publicEntryNumber
            //SalesPerson SalesPerson
        );
    }

    public newCustomerFromCode(customer: Customer): Customer {
        return new Customer(
            customer.customerNumber,
            customer.currency,
            //PaymentTerms PaymentTerms
            //CustomerGroup CustomerGroup
            customer.address,
            customer.balance,
            customer.dueAmount,
            customer.corporateIdentificationNumber,
            customer.city,
            customer.country,
            customer.email,
            customer.name,
            customer.zip,
            //VatZone VatZone
            customer.lastUpdated,
            customer.contacts,
            //Templates Templates
            //Totals Totals
            customer.deliveryLocations,
            //Invoices Invoices
            customer.eInvoicingDisabledByDefault,
            customer.self,
            customer.creditLimit,
            customer.telephoneAndFaxNumber,
            customer.website,
            //Layout Layout
            customer.mobilePhone,
            customer.publicEntryNumber
            //SalesPerson SalesPerson
        );
    }
}
