import React from 'react'
import { Wrapper, BoxWrapper } from './login.styles';
import useMsal from "../../auth/useMsal";

export default () => {
    const { loginAzAd, logoutAzAd } = useMsal();
    console.log('login');

    return (
        <>
          <Wrapper>
            <BoxWrapper>
                <div>
                    <p>
                        Please click on the button below
                        to enter e-conomic website.
                    </p>
                    <button onClick={loginAzAd}>Sign in</button>
                </div>
            </BoxWrapper>
          </Wrapper>
        </>
      );    
}
