import React, {useContext, useEffect, useState} from 'react'
import styled from 'styled-components';
import { EconomyDataContext } from '../../context/EconomyDataContext';
import { useLocation } from 'react-router-dom';

const NotFoundWrapper = styled.div`
  display: flex;
  align-content: left;
  justify-content: left;
  font-size: 1.0rem;
  margin-top: 20px;
  margin-left: 20px;
`;

const ErrorComponent: React.FC = () => {
  const [message, setMessage] = useState<string | null>(null);
  const {
    errorMessage
} = useContext(EconomyDataContext)!;

  useEffect(() => {
      setMessage(errorMessage);
  }, [errorMessage]);

  const getMsg = (): string => {
    return message ?? 'Unknown error';

  }  

  return (
        <NotFoundWrapper>
          {getMsg()}
        </NotFoundWrapper>
    );
}

export default ErrorComponent;
