import './App.scss';
import React from 'react';
import styled from 'styled-components';
//import ThemeContext from './context/ThemeContext';
import { AuthContextProvider } from './context/AuthContext';
import AppRouter from './route/AppRouter';
import {DateProvider} from "./context/DateContext";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUpload, faDownload, faUser, faUserCircle, faFile
} from '@fortawesome/free-solid-svg-icons';
import { AppSettingsStateProvider } from './context/AppSettingsStateContext';
import { EconomyDataProvider } from './context/EconomyDataContext';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const AppContainer = styled.div`
  color: ${props => props.theme.primaryTextColor};
  height: 100%;
  overflow: hidden;
`;

library.add(faUpload, faDownload, faUser, faUserCircle, faFile);

export default () => {
  // const theme = React.useContext(ThemeContext);
  return (
    <AppContainer>
      <AppSettingsStateProvider>
        <AuthContextProvider>
          <DateProvider>
            <EconomyDataProvider>
              <AppRouter />
            </EconomyDataProvider>
          </DateProvider>
        </AuthContextProvider>
      </AppSettingsStateProvider>
    </AppContainer>
  );
};
