import { CustomerDataBM } from '../types/BusinessModel/CustomerDataBM';
import { CustomerData } from '../types/ViewModel/CustomerData';
import { CustomerFactory } from './CustomerFactory';

export class CustomerDataFactory {
    private customerFactory = new CustomerFactory();

    constructor() {}

    public newCustomerData(customerDataBM: CustomerDataBM): CustomerData {
        return new CustomerData(this.customerFactory.newCustomerArray(customerDataBM.collection));
    }

    public newCustomerDataFromCode(customerData: CustomerData): CustomerData {
      return new CustomerData(this.customerFactory.newCustomerArrayFromCode(customerData.customerArray));
  }
}
