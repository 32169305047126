import { CountryDataBM } from '../types/BusinessModel/CountryDataBM';
import { CountryData } from '../types/ViewModel/CountryData';
import { CountryCodeItemFactory } from './CountryCodeItemFactory';
import { CurrencyCodeItemFactory } from './CurrencyCodeItem';

export class CountryDataFactory {
  private countryCodeItemFactory = new CountryCodeItemFactory();
  private currencyCodeItemFactory = new CurrencyCodeItemFactory();

  constructor() {}

  public newCountryData(countryDataBM: CountryDataBM): CountryData {
      return new CountryData(
          this.countryCodeItemFactory.newCountryCodeItemArray(countryDataBM.countryCodeList), 
          this.currencyCodeItemFactory.newCurrencyCodeItemArray(countryDataBM.currencyCodeList));
  }
}
