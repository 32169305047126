import * as Msal from "@azure/msal-browser";
import {LogLevel} from "@azure/msal-browser";

export const AuthConfig: Msal.Configuration = {
    auth: {
        clientId: 'd50fb0f7-c9ae-4a85-ab23-187e39558c11',   // Azure AD App registration application id
        authority: "https://login.microsoftonline.com/b1a4eea6-d399-4b68-9d0e-52fa27f93a58", // Azure AD Tenantid
        redirectUri: '/login',
        postLogoutRedirectUri: '/login',
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            piiLoggingEnabled: false
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 10000,
        loadFrameTimeout: 60000,
        // asyncPopups: false
    }
};

export const LoginRequest = {
    scopes: ["openid", "profile", "User.Read", "Directory.Read.All"]
};

export const TokenRequest = {
    scopes: ["User.Read", "Mail.Read"]
};
