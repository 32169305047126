import { CountryCodeItemBM } from "../BusinessModel/CountryCodeItemBM";
import { CurrencyCodeItemBM } from "../BusinessModel/CurrencyCodeItemBM";

export class CountryData {
    constructor(
        countryCodeList: CountryCodeItemBM[],
        currencyCodeList: CurrencyCodeItemBM[],
    ) {
        this.countryCodeList = countryCodeList;
        this.currencyCodeList = currencyCodeList;
    }
    countryCodeList: CountryCodeItemBM[];
    currencyCodeList: CurrencyCodeItemBM[];
}
