import { Invoice } from "./Invoice";

export class InvoiceData {
    constructor(
        dataReceived: boolean,
        invoiceArray: Invoice[]
    ) {
        this.dataReceived = dataReceived;
        this.invoiceArray = invoiceArray;
    }
    dataReceived: boolean;
    invoiceArray: Invoice[];
}
