import React, {useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import getContext from '../context/AppSettingsContext';
import { EconomyDataContext } from '../context/EconomyDataContext';
import useApi from './useApi';
import { AuthContext } from '../context/AuthContext';


export default () => {
    const history = useHistory();
    const appSettings = useContext(getContext());
    const { state, getApiData } = useApi();
    const endpoint = 'SaveToken';
    const {
        setTokenCheckDone,
        setHasToken,
        setErrorMessage,
        setTokenIsSavedSuccessfully,
        setIsSavingToken
    } = useContext(EconomyDataContext)!;
        
    const saveToken = async (token: string) => {
        const url = appSettings.api + endpoint + '?agreementgranttoken=' + token;
        var response = await axios
            .post(url, {})
            .then(resp => {
                console.log('Response:' + resp);
                // setHasToken(resp.data);
                // setTokenCheckDone(true);
                return resp;
            })
            .catch(error => {
                setErrorMessage(error.toString());
                history.push("/error");
            });   
        if (response && response.status === 200) {
            if (response.data === 1) setTokenIsSavedSuccessfully(true);
            else setTokenIsSavedSuccessfully(false);
            setIsSavingToken(false);
        }            
    };
    return { saveToken };
}
