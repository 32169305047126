import React, {useContext, useEffect, useState} from 'react';
import { GetButton,  ButtonsWrapper, Wrapper, DateWrapper, Buttons2Wrapper, SpinnerWrapper } from './MainHeading.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useRef} from "react"
import useGetInvoices from '../../api/useGetInvoices';
import useGetCustomers from '../../api/useGetCustomers';
import moment from 'moment';
import DatePicker from 'antd/lib/date-picker';
import locale from 'antd/es/date-picker/locale/en_GB'
import { InvoiceData } from '../../types/ViewModel/InvoiceData';
import { EconomyDataContext } from '../../context/EconomyDataContext';
import { CustomerData } from '../../types/ViewModel/CustomerData';
import { InvoiceDataFactory } from '../../factory/InvoiceDataFactory';
import { TailSpin } from  'react-loader-spinner';
import { InvoiceFactory } from '../../factory/InvoiceFactory';
import useGetFile from '../../api/useGetFile';
import xmlUtils from '../../utils/xmlUtils';
import * as FileSaver from "file-saver";
import getContext from '../../context/AppSettingsContext';
import useGetCountryCodes from '../../api/useGetCountryCodes';
import { CountryData } from '../../types/ViewModel/CountryData';
import { CustomerDataFactory } from '../../factory/CustomerDataFactory';
import { useHistory } from 'react-router-dom';
import { Customer } from '../../types/ViewModel/Customer';

type Props = {}

const MainHeading: React.FC<Props> = () => {
    const invoiceDataFactory = new InvoiceDataFactory();
    const customerFactory = new CustomerDataFactory();
    const history = useHistory();

    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const [isCreatingFiles, setIsCreatingFiles] = useState<boolean>(false);
    const [isFilesReceived, setIsFilesReceived] = useState<boolean>(false);
    const [invExList, setInvExList] = useState<number[]>([]);
    const { getInvoices } = useGetInvoices();
    const { getCustomers } = useGetCustomers();
    const { getExcel, getPdf } = useGetFile();
    const { getCountryCodes } = useGetCountryCodes();
    const appSettings = useContext(getContext());
    const [invData, setInvData] = useState<InvoiceData | null>(null);
    const {
        invoiceData,
        updatedInvoiceData,
        setUpdatedInvoiceData,
        customerData,
        updatedCustomerData,
        setUpdatedCustomerData,
        countryData,
        isCreatingPdf,
        isCreatingExcel,
        setIsCreatingPdf,
        setIsCreatingExcel,
        hasToken,
        isRetrievingPdfFromVisma,
        setErrorMessage
    } = useContext(EconomyDataContext)!;
    const { createInvoiceXmLStr, createCustomerXmLStr, updateCustCountryCode } = xmlUtils();
        
    useEffect(() => {
        getCountryCodes();
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        setFromDate(firstDay);
        setToDate(lastDay);
    }, []);

    useEffect(() => {
        if (invoiceData) {
            if (invoiceData.dataReceived) {
                setInvData(invoiceData);
                getCustomers(createCustListParam(invoiceData));
            } else {
               setIsLoadingData(false);
               setErrorMessage('No data received from Visma e-conomic');
               history.push("/error");
           }
        }
    }, [invoiceData]);

    useEffect(() => {
        if (customerData) {
            updateCustomerData(customerData);
            updateInvoiceData(customerData);
        }
    }, [customerData]);

    useEffect(() => {
        if (isCreatingFiles && !isCreatingExcel) {
            setIsCreatingPdf(true);
            getPdf(fromDate!, toDate!, getInvExList(), new Date());
        }
    }, [isCreatingExcel]);

    useEffect(() => {
        if (isCreatingFiles && !isCreatingPdf) {
            setIsCreatingFiles(false);
        }
    }, [isCreatingPdf]);

    useEffect(() => {
        setIsCreatingFiles(isRetrievingPdfFromVisma);
    }, [isRetrievingPdfFromVisma]);

    const updateCustomerData = (customerData: CustomerData) => {
        let custData = customerFactory.newCustomerDataFromCode(customerData);
        if (countryData) {
            custData.customerArray.forEach(cust => {
                cust = updateCustCountryCode(cust, countryData);
            });
        }
        setUpdatedCustomerData(custData);
    } 

    const updateInvoiceData = (customerData: CustomerData) => {
        if (invoiceData) {
            var invData = invoiceDataFactory.newInvoiceDataFromCode(invoiceData);
            invData.invoiceArray.forEach(inv => {
                const customer = customerData.customerArray.find(cust => cust.customerNumber === inv.customer.customerNumber);
                if (customer) {
                    inv.customer.name = customer.name;
                }
            });
            setUpdatedInvoiceData(invData);
            setIsLoadingData(false);
        }
    }

    const createCustListParam = (invData: InvoiceData): string => {
        let custNrListquery: string = '';
        if (invData.invoiceArray && invData.invoiceArray.length > 0) {
            let custNrList = invData.invoiceArray.map(o => o.customer.customerNumber);
            custNrList = custNrList.filter(function(v,i) { return custNrList.indexOf(v) == i; });
            custNrList.forEach(o => custNrListquery += o + ',');
            custNrListquery = custNrListquery.substring(0, custNrListquery.length - 1);
        }
        return custNrListquery;
    }

    const handleGetInvoices = () => {
        if (fromDate && toDate) {
            setIsLoadingData(true);
            getInvoices(fromDate, toDate);
        }
    };

    const createXmlFile = (data: any, filename: string) => {
        var blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, filename + '.xml');
    }

    const getInvExList = (): string => {
        let invExListStr = '';
        updatedInvoiceData!.invoiceArray.forEach(inv => {
            if (!inv.checked) {
                invExListStr += inv.bookedInvoiceNumber + ',';
            }
        });
        if (invExListStr.length > 0) {
            invExListStr = invExListStr.substring(0, invExListStr.length-1);
        }
        return invExListStr;
    }

    const handleCreateFile = () => {
        // Create files
        if (fromDate && toDate && updatedInvoiceData && updatedCustomerData) {
            setIsCreatingFiles(true);
            // setIsCreatingPdf(true);
            setIsCreatingExcel(true);
            getExcel(fromDate, toDate, getInvExList(), new Date());
            let fileDate = moment(new Date).format('DDMMYYYY')
            createXmlFile(createInvoiceXmLStr(updatedInvoiceData, appSettings.klientNr, getInvExList()), 'faktura-' + fileDate);
            createXmlFile(createCustomerXmLStr(updatedCustomerData, updatedInvoiceData, appSettings.klientNr, getInvExList(), countryData), 'kunde-' + fileDate);
        }
    }

    const onChangeFromDate = (momentDate) => {
        setFromDate(momentDate.toDate())
        const date =  moment(moment(momentDate).format('MM.DD.YYYY')).toDate();
    };

    const onChangeToDate = (momentDate) => {
        setToDate(momentDate.toDate())
        const date =  moment(moment(momentDate).format('MM.DD.YYYY')).toDate();
    };

    const getSpinner = () => {
        return (
            <TailSpin
                width={40}
                height={40}
                color='grey'
                ariaLabel='TailSpin'
            />
        )
    }

    return (
        <>
            <Wrapper>
                <ButtonsWrapper>
                    <GetButton isSelected={true} onClick={handleGetInvoices}><FontAwesomeIcon icon="download" title="Click to get invoice and customer data"/></GetButton>
                </ButtonsWrapper>
                <DateWrapper>
                    <h4>From: </h4>
                    <DatePicker
                            className="date-picker"
                            format={'DD.MM.YYYY'}
                            clearIcon={false}
                            value={moment(fromDate, 'DD.MM.YYYY')}
                            defaultValue={moment(fromDate, 'DD.MM.YYYY')}
                            placeholder={''}
                            onChange={e => onChangeFromDate(e)}
                            locale={locale}
                        />

                </DateWrapper>
                <DateWrapper>
                    <h4>To: </h4>
                    <DatePicker
                            className="date-picker"
                            format={'DD.MM.YYYY'}
                            clearIcon={false}
                            value={moment(toDate, 'DD.MM.YYYY')}
                            defaultValue={moment(toDate, 'DD.MM.YYYY')}
                            placeholder={''}
                            onChange={e => onChangeToDate(e)}
                            locale={locale}
                        />

                </DateWrapper>
                <ButtonsWrapper>
                    <GetButton isSelected={true} onClick={handleCreateFile}><FontAwesomeIcon icon="upload" title="Click to generate files"/></GetButton>
                </ButtonsWrapper>
                {(isLoadingData || isCreatingFiles) &&
                    <SpinnerWrapper>
                        {getSpinner()}
                    </SpinnerWrapper>
                }

            </Wrapper>
        </>
    );
}
export default MainHeading;
