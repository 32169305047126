const constants = {
    pageHeaderHeight: '65px',
    pageHeaderMargin: '0.5rem',
}

// const calculatedPageHeaderSize = `calc(${constants.pageHeaderHeight} + ${constants.pageHeaderMargin})`;

// const colors = {
//     darkBlue: '#00447e',
//     lightBlue: '#009fdf',
//     orange: '#ec6825',
//     atSea: '#edf4fb',
//     inPort: '#fffce1'
// }

// const selectableElement = (isSelected) => `
//     cursor: pointer;
//     transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
//     ${isSelected ?
//         `background: ${colors.darkBlue} !important; 
//          border: 1px solid #04265a;
//          color: #fff;
//          ` :
//         `
//          &:hover{
//             background: #fff;
//          }
//          &:active {
//              transform: scale(0.9);
//              transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
//          }
//          `}
// `;

export default {
//    colors: colors,
    constants: constants,
    // calculatedPageHeaderSize: calculatedPageHeaderSize,
    // contentHeight: `calc(100vh - ${calculatedPageHeaderSize})`,
    // primaryTextColor: colors.darkBlue,
    // selectableElement: selectableElement,
};