import React from "react";

interface iSettings {
  api: string;
  klientNr: string;
}

let AppSettingsContext: React.Context<iSettings>;
export class AppSettingsGetter {
  public settings: any;
  public async getAppSettings() {
    console.log("Reading assets/appsetting.json");

    this.settings = {
      api:
        process.env.REACT_APP_API ||
        "https://economicexporterapi.azurewebsites.net/",
      //api: "http://localhost:7071/",
      klientNr: process.env.REACT_APP_CLIENT_NUMBER || "5851",
    };

    console.log("Api:" + this.settings.api);
    console.log("klientNr:" + this.settings.klientNr);
    AppSettingsContext = React.createContext(this.settings);
    return this.settings;
  }
}

let getContext = function () {
  return AppSettingsContext;
};
export default getContext;
