import React, {useContext, useEffect, useState} from 'react';
import { Wrapper, ListOuterWrapper, ListHeadingWrapper, ListWrapper, MainListWrapper, CheckBoxWrapper, GetButton,
    ItemWrapper1, ItemWrapper2, ItemWrapper3, ItemWrapper4, ItemWrapper5, 
    ItemWrapper6, ItemWrapper7, ItemWrapper8, ItemWrapper9, ItemWrapper10, ItemWrapper11, 
    HeadingWrapper7, HeadingWrapper8, HeadingWrapper9, HeadingWrapper10, HeadingWrapper11, HeadingWrapper12, ItemWrapper12, NumberWrapper} from './MainList.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useRef} from "react"
import useGetInvoices from '../../api/useGetInvoices';
import useGetCustomers from '../../api/useGetCustomers';
import { EconomyDataContext } from '../../context/EconomyDataContext';
import { InvoiceData } from '../../types/ViewModel/InvoiceData';
import { Invoice } from '../../types/ViewModel/Invoice';
import { InvoiceDataFactory } from '../../factory/InvoiceDataFactory';
import './MainList.scss';
import useGetPdf from '../../api/useGetPdf';

type Props = {}

const MainList: React.FC<Props> = () => {
    const invoiceDataFactory = new InvoiceDataFactory();
    const [invData, setInvData] = useState<InvoiceData | null>(null);
    const [isHeadingChecked, setIsHeadingChecked] = useState<boolean>(true);
    const { getInvoicePdfFromVisma } = useGetPdf();
    const {
        isLoadingInvoiceData,
        isLoadingCustomerData,
        updatedInvoiceData,
        setUpdatedInvoiceData,
        setIsRetrievingPdfFromVisma
    } = useContext(EconomyDataContext)!;

    useEffect(() => {
        if (updatedInvoiceData) {
            setInvData(updatedInvoiceData);
        }
    }, [updatedInvoiceData]);

    const onCheckboxHeading = () => {
        if (invData) {
            var invDt = invoiceDataFactory.newInvoiceDataFromCode(invData);
            invDt.invoiceArray.forEach(inv => {
                inv.checked = !isHeadingChecked;
            });
            setInvData(invDt);
            setUpdatedInvoiceData(invData);
        }
        setIsHeadingChecked(!isHeadingChecked);
    }

    const onCheckboxInvoice = (selInvItem: Invoice) => {
        if (invData) {
            var inv = invoiceDataFactory.newInvoiceDataFromCode(invData);
            const invItem = inv.invoiceArray.find(o => o.bookedInvoiceNumber === selInvItem.bookedInvoiceNumber);
            if (invItem) {
                invItem.checked = !invItem.checked;
                setInvData(inv);
                setUpdatedInvoiceData(inv);
            }
        }
    }

    const getCheckbox = (inv: Invoice | null) => {
        if (inv) {
            return (
                <CheckBoxWrapper>
                <div className='chkbox'>
                    <input 
                        type="checkbox" 
                        name={'chbox1'} 
                        value={'1'} 
                        onChange={() => onCheckboxInvoice(inv)}
                        checked={inv.checked} 
                        autoComplete="off">
                    </input>
                </div>
                </CheckBoxWrapper>
            )
        } else {
            return (
                <CheckBoxWrapper>
                    <div className='chkbox'>
                        <input 
                            type="checkbox" 
                            name={'chbox1'} 
                            value={'1'} 
                            onChange={onCheckboxHeading}
                            checked={isHeadingChecked} 
                            autoComplete="off">
                        </input>
                    </div>
                </CheckBoxWrapper>
            )
        }
    }

    const createHeading = () => {
        return (
            <ListHeadingWrapper>
                <ItemWrapper1>{getCheckbox(null)}</ItemWrapper1>
                <ItemWrapper2>Invoice Nr</ItemWrapper2>
                <ItemWrapper3>Date</ItemWrapper3>
                <ItemWrapper4>Customer Nr</ItemWrapper4>
                <ItemWrapper5>Customer Name</ItemWrapper5>
                <ItemWrapper6>Due Date</ItemWrapper6>
                <HeadingWrapper7>Net Amount (DKK)</HeadingWrapper7>
                <HeadingWrapper8>VAT (DKK)</HeadingWrapper8>
                <HeadingWrapper9>Gross amount (DKK)</HeadingWrapper9>
                <HeadingWrapper10>Currency Amount</HeadingWrapper10>
                <HeadingWrapper11>Currency</HeadingWrapper11>
                <HeadingWrapper12>Pdf</HeadingWrapper12>
            </ListHeadingWrapper>        
        )
    }

    const isColor = (index: number): boolean => {
        if ((index % 2) === 0) {
            return true;
        }
        return false;
    }

    const numberWith2Dec = (val: number): string => {
        return Number(val).toFixed(2); 
    }

    const getCalculatedAmount = (item: Invoice): number => {
        return item.netAmount * item.exchangeRate / 100;
    }

    const getNetAmount = (item: Invoice): string => {
        if (item.currency === 'DKK') {
            return numberWith2Dec(item.netAmount);
        }
        return numberWith2Dec(getCalculatedAmount(item));
    }

    const getGrossAmount = (item: Invoice): string => {
        if (item.currency === 'DKK') {
            return numberWith2Dec(item.grossAmount);
        }
        return numberWith2Dec(getCalculatedAmount(item));
    }

    const getCurrencyAmount = (item: Invoice): string => {
        if (item.currency === 'DKK') {
            return '';
        }
        return numberWith2Dec(item.netAmount);
    }

    const handlePdfLink = (item: Invoice) => {
        setIsRetrievingPdfFromVisma(true);
        getInvoicePdfFromVisma(item.bookedInvoiceNumber.toString());
    }

    const getPdfLink = (item: Invoice) => {
        return (
            <GetButton isSelected={false} onClick={() => handlePdfLink(item)}><FontAwesomeIcon icon="file" className='fa-file' title="Click to retrieve pdf for invoice"/></GetButton>
        )
    }

    const getPdfLink2 = (item: Invoice) => {
        return (
            <h4 className='pdfLink' onClick={() => handlePdfLink(item)}>pdf</h4>
        )
    }

    const createList = () => {
        if (invData && invData.invoiceArray && invData.invoiceArray.length > 0) {
            return (
                <ListOuterWrapper>
                    <div className="rotation-tree-list">
                        {invData!.invoiceArray.map((item: Invoice, index) => {
                            return (
                                <ListWrapper key={item.bookedInvoiceNumber} isColor={isColor(index)}>
                                    <ItemWrapper1>{getCheckbox(item)}</ItemWrapper1>
                                    <ItemWrapper2>{item.bookedInvoiceNumber.toString()}</ItemWrapper2>
                                    <ItemWrapper4>{item.date.toString()}</ItemWrapper4>
                                    <ItemWrapper3>{item.customer.customerNumber.toString()}</ItemWrapper3>
                                    <ItemWrapper5>{item.customer.name}</ItemWrapper5>
                                    <ItemWrapper6>{item.dueDate.toString()}</ItemWrapper6>
                                    <ItemWrapper7>
                                        <NumberWrapper isNegative={item.netAmount < 0}>
                                            {getNetAmount(item)}
                                        </NumberWrapper>
                                    </ItemWrapper7>
                                    <ItemWrapper7>
                                        <NumberWrapper isNegative={item.vatAmount < 0}>
                                            {numberWith2Dec(item.vatAmount)}
                                        </NumberWrapper>
                                    </ItemWrapper7>
                                    <ItemWrapper7>
                                        <NumberWrapper isNegative={item.grossAmount < 0}>
                                            {getGrossAmount(item)}
                                        </NumberWrapper>
                                    </ItemWrapper7>
                                    <ItemWrapper7>
                                        <NumberWrapper isNegative={item.currency !== 'DKK' && getCalculatedAmount(item) < 0}>
                                            {getCurrencyAmount(item)}
                                        </NumberWrapper>
                                    </ItemWrapper7>
                                    <ItemWrapper11>{item.currency}</ItemWrapper11>
                                    <ItemWrapper12>{getPdfLink(item)}</ItemWrapper12>
                                </ListWrapper>    
                            )
                        })}
                    </div>
                </ListOuterWrapper>
            )
        }
        return (
            <div>No data to display</div>
        )
    }

    return (
        <Wrapper>
            {(!isLoadingCustomerData && !isLoadingInvoiceData) &&
                <MainListWrapper>
                    {createHeading()}
                    {createList()}
                </MainListWrapper>
            }
        </Wrapper>
    );
}
export default MainList;
