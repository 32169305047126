export class CurrencyCodeItem {
    constructor(
        valuta: string,
        valutaKode: string,
    ) {
        this.valuta = valuta;
        this.valutaKode = valutaKode;
    }
    valuta: string
    valutaKode: string
}
