import React, {useContext, useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Login from '../pages/login/login';
import { PrivateRoute } from './PrivateRoute';
import useMsal from "../auth/useMsal";
import styled from "styled-components";
import MainComponent from "../pages/main/Main";
import PageHeader from '../components/page-header/PageHeader';
import SidePanelLeft from '../components/side-panel/SidePanelLeft';
import ErrorComponent from "../pages/error/Error";
import LogoutComponent from "../pages/logout/logout";
import IsAuthorizedComponent from '../pages/is-authorized/IsAuthorized';
import AuthorizeComponent from '../pages/authorize/Authorize';
import TokenRetrievelComponent from '../pages/token-retrievel/TokenRetrievel';
import useGetHasToken from '../api/useGetHasToken';
import { EconomyDataContext } from '../context/EconomyDataContext';
import { Audio } from  'react-loader-spinner';
import { TailSpin } from  'react-loader-spinner';

const ContentWrapper = styled.div`
    height: calc(100vh - 65px);
`;

const Wrapper = styled.div`
  outline: none;
`;

const PageWrapper = styled.div`
  outline: none;
  display: grid;
  height: calc(100vh - 65px);
`;

const SpinnerWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: baseline;
  //margin: 4px 6px 0 500px;
`;

export default () => {
  const { checkAuthStatus, initHandleRedirect} = useMsal();
  const { loggedInUser, isAuthenticating, isVerifyingToken } = React.useContext(AuthContext);
  const [isTokenRetrievel, setIsTokenRetrievel] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  const { getHasToken } = useGetHasToken();
  const {
    tokenCheckDone,
    hasToken,
    doTokenCheck,
    setDoTokenCheck,
    setTokenCheckDone,
    isSavingToken
} = useContext(EconomyDataContext)!;

  const LoginRoute = () => {
    if (loggedInUser) {
      return <Route path='/login'><Redirect to='/main'/></Route>
    }
    return <Route path='/login' component={Login}/>
  }

  // useEffect(() => {
  //   if (location) {
  //     if(location.pathname !== '/tokenretrievel'){
  //       setDoTokenCheck(true);
  //     } else {
  //       const windowParams = new URLSearchParams(window.location.search);
  //       let token = windowParams.get('token');
  //       if (token) {
  //         windowParams.set('token', token)
  //         history.push({pathname: '/tokenretrievel', search: windowParams.toString()});
  //       }
  //     }
  //   }
  // }, [location]);

  // useEffect(() => {
  //   if (tokenCheckDone) {
  //     if(location.pathname !== '/tokenretrievel'){
  //       if (!hasToken) {
  //         history.push("/authorize");
  //       } else {
  //         history.push("/main");
  //       }
  //     } else {
  //       if (!hasToken) {
  //         const windowParams = new URLSearchParams(window.location.search);
  //         let token = windowParams.get('token');
  //         if (token) {
  //           windowParams.set('token', token)
  //           history.push({pathname: '/tokenretrievel', search: windowParams.toString()});
  //         }
  //       } else {
  //         history.push("/main");
  //       }
  //     }
  //   }
  // }, [location, tokenCheckDone, hasToken]);

  useEffect(() => {
    if (location && location.pathname === '/tokenretrievel') {
      setIsTokenRetrievel(true);
    }
    if (doTokenCheck && tokenCheckDone) {
      if (location.pathname !== '/tokenretrievel'){
        if (!hasToken) {
          history.push("/authorize");
        } else {
          history.push("/main");
        }
      } else {
        if (!hasToken) {
          const windowParams = new URLSearchParams(window.location.search);
          let token = windowParams.get('token');
          if (token) {
            windowParams.set('token', token)
            history.push({pathname: '/tokenretrievel', search: windowParams.toString()});
          }
        } else {
          history.push("/main");
        }
      }
      setTokenCheckDone(false);
      setDoTokenCheck(false);
    }
  }, [location, tokenCheckDone, hasToken, doTokenCheck]);

  // useEffect(() => {
  //   if (!isCheckingToken) {
  //     if (!hasToken) {
  //       history.push("/authorize");
  //     } else {
  //       history.push("/main");
  //     }
  //   }
  // }, [isCheckingToken, hasToken]);

  useEffect(() => {
    if (loggedInUser && !isTokenRetrievel) {
      getHasToken();
    }
  }, [loggedInUser]);

  useEffect(() => {
    initHandleRedirect();
    checkAuthStatus();
  }, []);

  const getSpinner = () => {
    return (
        <TailSpin
            width={40}
            height={40}
            color='grey'
            ariaLabel='TailSpin'
        />
    )
  }
  
  return (
      <Wrapper>
        {/* {!isVerifyingToken && !isAuthenticating && !isCheckingToken &&
          <SpinnerWrapper>
            {getSpinner()}
        </SpinnerWrapper>
        } */}
        <PageHeader></PageHeader>
        {!isVerifyingToken && !isAuthenticating &&
          <PageWrapper>
            {/* {<SidePanelLeft></SidePanelLeft>} */}
            <ContentWrapper className="full-height">
              <Switch>
                <Route exact path='/'>{<Redirect to={'/login'}/>}</Route>
                {console.log('location.pathname = ' + location.pathname)}
                {LoginRoute()}
                <PrivateRoute exact path='/'><Redirect to='/login'/></PrivateRoute>
                <PrivateRoute path='/main' component={MainComponent}></PrivateRoute>
                <PrivateRoute path='/error' component={ErrorComponent}></PrivateRoute>
                <PrivateRoute path='/authorize' component={AuthorizeComponent}></PrivateRoute>
                <PrivateRoute path='/tokenretrievel' component={TokenRetrievelComponent}></PrivateRoute>
                <PrivateRoute component={ErrorComponent} />
              </Switch>
            </ContentWrapper>
          </PageWrapper>
        }
      </Wrapper>
  );
}
