import { Action } from "../types/Action";
import { State } from "../types/State";

export default (state: State, action: Action): State => {
    switch (action.type) {
        case 'request_started':
            return {
                ...state,
                status: 'loading',
                isLoading: true,
                error: null
            };
        case 'request_successful':
            return {
                ...state,
                status: 'success',
                result: action.result,
                isLoading: false,
                error: null
            };
        case 'request_failed':
            return {
                ...state,
                status: 'error',
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
}
