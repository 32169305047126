import React, {useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import axios, { AxiosRequestConfig } from 'axios';
import getContext from '../context/AppSettingsContext';
import { EconomyDataContext } from '../context/EconomyDataContext';
import * as FileSaver from "file-saver";
import moment from "moment";
import { time } from 'console';
import { AuthContext } from '../context/AuthContext';

export default () => {
    const appSettings = useContext(getContext());
    const {
        setIsRetrievingPdfFromVisma,
    } = useContext(EconomyDataContext)!;
    const { loggedInUser } = React.useContext(AuthContext);

    const prettifyDateTime = (date: Date) => date ? moment(date).format('DD.MM.YYYY HH.mm.ss') : '';

    const getPdfFilename = (filename: string, invoiceNr: string, timestamp: Date): string => {
        return filename + '-' + invoiceNr + ' ' + prettifyDateTime(timestamp) + '.pdf';
    }

    const savePdf = (filename: string, data: any) => {
        var blob = new Blob([data], { type: 'application/pdf' });
        FileSaver.saveAs(blob, filename);
    }

    const getInvoicePdfFromVisma = async (invoiceNr: string) => {
        const url = appSettings.api + 'GetInvoicePdfFromVisma?invoiceNr=' + invoiceNr;
        const timestamp = new Date();
        const pdfFilename = getPdfFilename('InvoicePdf', invoiceNr, timestamp);
        const headers = {'Content-Type': 'blob'};
        const config: AxiosRequestConfig = {responseType: 'arraybuffer', headers};
        var response = await axios
            .get(url, config)
            .then(resp => {
                console.log('Response:' + resp);
                return resp;
            })
            .catch(error => {
            });   
            if (!response) {
            } else if (response.status !== 200) {
                setIsRetrievingPdfFromVisma(false);
                if (response.data) {
                } else {
                }
            } else {
                savePdf(pdfFilename, response.data);
                setIsRetrievingPdfFromVisma(false);
            }
    };

    return { getInvoicePdfFromVisma };
}
