import { Customer } from "../types/ViewModel/Customer";
import { CustomerData } from "../types/ViewModel/CustomerData";
import { Invoice } from "../types/ViewModel/Invoice";
import { InvoiceData } from "../types/ViewModel/InvoiceData";
import moment from "moment";
import { CountryData } from "../types/ViewModel/CountryData";
import { CustomerFactory } from '../factory/CustomerFactory';

export default () => {
    const customerFactory = new CustomerFactory();

    const createTag = (tagName: string, tagValue: string | null, nrTab: number): string => {
        let str: string = '';
        let ind = 0;
        while (ind++ < nrTab) { str += ' '}
        if (!tagValue) {
            str += '<' + tagName + ' />';
        } else {
            str += '<' + tagName + '>' + tagValue + '</' + tagName + '>';
        }
        return str + '\n';
    }
    
    const removeLF = (str: string): string => {
        return str.replace('\n', ' ');
    }
    
    const replaceEscapeChars = (str: string): string => {
        str = str.replace('&', '&amp;');
        str = str.replace('"', '&quot;');
        str = str.replace('\'', '&apos;');
        str = str.replace('<', '&lt;');
        str = str.replace('>', '&gt;');
        return str;
    }
    
    const createTransKode = (inv: Invoice): string => {
        if (inv.grossAmount < 0) return '9';
        return '1';
    }
    
    const getAmountOld = (inv: Invoice): string => {
        let amount: number = 0;
        if (inv.currency === 'DKK') {
            amount = inv.grossAmount;
        } else {
            amount = inv.grossAmount * inv.exchangeRate / 100;
        }
        if (amount < 0) amount = amount * -1;
        return Math.round((amount * 100)).toString();
    }
    
    const getAmount = (inv: Invoice): string => {
        let amount: number = inv.grossAmount;
        if (amount < 0) amount = amount * -1;
        return Math.round((amount * 100)).toString();
    }
    
    const getInvExchangeCode = (inv: Invoice): string => {
        return inv.currency;
    }
    
    const getDateFormat = (date: Date): string => {
        return moment(date).format('YYMMDD')
    }
    
    const createInvoiceTag = (inv: Invoice, klientNr: string): string => {
        let str = '  <Faktura>\n';
        str += createTag('Transaksjonskode', createTransKode(inv) , 4);
        str += createTag('Klientnr', klientNr, 4);
        str += createTag('Kundenr', inv.customer.customerNumber.toString(), 4);
        str += createTag('Fakturanr', inv.bookedInvoiceNumber.toString(), 4);
        str += createTag('Fakturadato', getDateFormat(inv.date), 4);
        str += createTag('Forfallsdato', getDateFormat(inv.dueDate), 4);
        str += createTag('Belop', getAmount(inv), 4);
        str += createTag('Rabattbetingelse', null, 4);
        str += createTag('Fritekst', null, 4);
        str += createTag('Selgernr', null, 4);
        str += createTag('Prosjektnr', null, 4);
        str += createTag('Valutakode', getInvExchangeCode(inv), 4);
        str += '  </Faktura>\n';
        return str;
    }
    
    const getInvList = (invData: InvoiceData, invExListStr: string): Invoice[] => {
        const invExList = invExListStr.split(',');
        const filteredInvList = invData.invoiceArray.filter(inv => {
            if (invExList.find(invNr => invNr === inv.bookedInvoiceNumber.toString())) return false;
            return true;
        });
        return filteredInvList;
    }
    
    const createInvoiceXmLStr = (invData: InvoiceData, klientNr: string, invExListStr: string): string => {
        let xmlStr: string = '<?xml version="1.0" encoding="UTF-8"?>' + '\n';
        xmlStr += '<Fakturaer xmlns="urn:sgfinans-factoring-no:faktura_schema">\n';
        var invList = getInvList(invData, invExListStr);
        invList.forEach(o => xmlStr += createInvoiceTag(o, klientNr));
        xmlStr += '</Fakturaer>\n';
        return xmlStr;
    }
    
    const createOrgPersonnrTag = (cust: Customer): string => {
        let str = '    <OrgPersonnr>\n';
        str += createTag('Organisasjonsnr', cust.corporateIdentificationNumber, 6);
        str += '    </OrgPersonnr>\n';
        return str;
    }
    
    const createNavnTag = (cust: Customer): string => {
        let str = '    <Navn>\n';
        str += createTag('EtternavnFirmanavn', replaceEscapeChars(cust.name) , 6);
        str += '    </Navn>\n';
        return str;
    }
    
    const createAdresseTag = (cust: Customer): string => {
        let str = '    <Adresse>\n';
        str += createTag('Postadresse', removeLF(replaceEscapeChars(cust.address)), 6);
        str += createTag('Postnr', cust.zip, 6);
        str += createTag('Poststed', replaceEscapeChars(cust.city), 6);
        str += createTag('Landnavn', replaceEscapeChars(cust.country), 6);
        str += createTag('LandkodeAlfa', cust.landkodeAlfa, 6);
        str += createTag('LandkodeNumerisk', cust.landkodeNumerisk, 6);
        str += '    </Adresse>\n';
        return str;
    }
    
    const getCustExchangeCode = (cust: Customer, countryData: CountryData | null): string | null => {
        if (countryData) {
            const currCode = countryData.currencyCodeList.find(o => o.valuta === cust.landkodeNumerisk);
            if (currCode) return currCode.valutaKode;
        }
        return cust.currency;
    }
    
    const createCustomerTag = (cust: Customer, klientNr: string, countryData: CountryData | null): string => {
        let str = '  <Kunde>\n';
        str += createTag('Klientnr', klientNr, 4);
        str += createTag('Kundenr', cust.customerNumber.toString(), 4);
        str += createOrgPersonnrTag(cust);
        str += createTag('Kontaktperson', null, 4);
        str += createTag('Telefonnr', cust.telephoneAndFaxNumber, 4);
        str += createTag('Bankgironr', null, 4);
        str += createTag('DistriktSelgernr', null, 4);
        str += createNavnTag(cust);
        str += createAdresseTag(cust);
        str += createTag('Valutakode', getCustExchangeCode(cust, countryData), 4);
        str += '  </Kunde>\n';
        return str;
    }
    
    const updateCustCountryCode = (cust: Customer, countryData: CountryData | null): Customer => {
        if (countryData) {
            const country = countryData.countryCodeList.find(o => o.land.toLowerCase() === cust.country.toLowerCase());
            if (country) {
                cust.landkodeAlfa = country.alpha2Code;
                cust.landkodeNumerisk = country.landnr;
            }
        }
        return cust;
    }
    
    const updateCustCountryCodeFromCurrency = (cust: Customer, countryData: CountryData | null, curr: string): Customer => {
        if (countryData) {
            const currCode = countryData.currencyCodeList.find(o => o.valutaKode === curr);
            if (currCode) {
                const country = countryData.countryCodeList.find(o => o.landnr === currCode.valuta);
                if (country) {
                    cust.landkodeAlfa = country.alpha2Code;
                    cust.landkodeNumerisk = country.landnr;
                }
            }
        }
        return cust;
    }
    
    const createCustomerXmLStr = (custData: CustomerData, invData: InvoiceData, klientNr: string, invExListStr: string, countryData: CountryData | null): string => {
        let xmlStr: string = '<?xml version="1.0" encoding="UTF-8"?>' + '\n';
        xmlStr += '<Kunder xmlns="urn:sgfinans-factoring-no:kunde_schema">\n';
        var invList = getInvList(invData, invExListStr);
        // Filter customer list for invoices
        var custList = custData.customerArray.filter(cust => {
            if (invList.find(inv => inv.customer.customerNumber === cust.customerNumber)) return true;
            return false;
        });

        invList.forEach(inv => {
            var cust = custList.find(c => c.customerNumber === inv.customer.customerNumber && c.currency === inv.currency);
            if (!cust) {
                var existingCust = custList.find(c => c.customerNumber === inv.customer.customerNumber);
                if (existingCust) {
                    var newCust = customerFactory.newCustomerFromCode(existingCust);
                    newCust = updateCustCountryCodeFromCurrency(newCust, countryData, inv.currency);
                    newCust.currency = inv.currency;
                    custList.push(newCust);
                }
            }
        });
    
        custList.forEach(o => xmlStr += createCustomerTag(o, klientNr, countryData));
        xmlStr += '</Kunder>\n';
        return xmlStr;
    }

    return { createInvoiceXmLStr, createCustomerXmLStr, updateCustCountryCode };
}
