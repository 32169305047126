export class InvCustomer {
    constructor(
        customerNumber: number,
        name: string | null,
        self: string,
    ) {
        this.customerNumber = customerNumber;
        this.name = name;
        this.self = self;
    }
    customerNumber: number;
    name: string | null;
    self: string;
}
