import { CurrencyCodeItemBM } from '../types/BusinessModel/CurrencyCodeItemBM';
import { InvoiceDataBM } from '../types/BusinessModel/InvoiceDataBM';
import { CurrencyCodeItem } from '../types/ViewModel/CurrencyCodeItem';
import { InvoiceData } from '../types/ViewModel/InvoiceData';
import { InvoiceFactory } from './InvoiceFactory';

export class CurrencyCodeItemFactory {
    constructor() {}

    public newCurrencyCodeItemArray(currCodeItemBMArray: CurrencyCodeItemBM[]): CurrencyCodeItem[] {
        var array: CurrencyCodeItem[] = [];
        if (currCodeItemBMArray && currCodeItemBMArray.length > 0) {
            currCodeItemBMArray.forEach(item => {
                array.push(this.newCurrencyCodeItem(item));
            });
        }
        return array;
    }

    public newCurrencyCodeItem(currCodeItemBM: CurrencyCodeItemBM): CurrencyCodeItem {
        return new CurrencyCodeItem(currCodeItemBM.valuta, currCodeItemBM.valutaKode);
    }
}
