import {RoleTypeEnum} from "../../enums/roleTypeEnum";

export class Role {
    constructor(
        roleType: RoleTypeEnum,
        isWriter: boolean
    ) {
        this.roleType = roleType;
        this.isWriter = isWriter;
    }
    roleType: RoleTypeEnum;
    isWriter: boolean | null;
}
