import { PdfBM } from '../types/BusinessModel/PdfBM';
import { Pdf } from '../types/ViewModel/Pdf';

export class PdfFactory {
    constructor() {}

    public newPdf(pdfBM: PdfBM): Pdf {
        return new Pdf(pdfBM.download);
    }

    public newPdfFromCode(pdf: Pdf): Pdf {
        return new Pdf(pdf.download);
    }
}
