import React, {useContext, useEffect} from 'react';
import { EconomyDataContext } from '../context/EconomyDataContext';
import { InvoiceDataFactory } from '../factory/InvoiceDataFactory';
import { InvoiceDataBM } from '../types/BusinessModel/InvoiceDataBM';
import useApi from './useApi';
import moment from 'moment';
import { CountryDataFactory } from '../factory/CountryDataFactory';
import { CountryDataBM } from '../types/BusinessModel/CountryDataBM';

export default () => {
    const countryDataFactory = new CountryDataFactory();

    const endpoint = 'GetCountryData';
    const { state, getApiData } = useApi();
    const {
        setIsLoadingCountryData,
        setCountryData,
    } = useContext(EconomyDataContext)!;

    const getCountryCodes = () => {
        getApiData(endpoint, '');
    };

    useEffect(() => {
        setIsLoadingCountryData(state.isLoading);
        if (state.status === 'success'){
            const countryDataBM = state.result as unknown as CountryDataBM;
            const countryData = countryDataFactory.newCountryData(countryDataBM);
            setCountryData(countryData);
        }
    }, [state]);

    return { state, getCountryCodes };
}
