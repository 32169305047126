import React, {useEffect, useState} from 'react'
import { UserInfo } from "./UserInfo.styles";
import { AuthContext } from "../../../context/AuthContext";
import useMsal from "../../../auth/useMsal";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, Label, Wrapper } from '../HeaderItem.styles';
import HeaderItem from '../HeaderItem';

type Props = {}

const UserInfoComponent: React.FC<Props> = () => {
    const [isUserInfoVisible, setIsUserInfoVisible] = useState(false);
    const { role,  loggedInUser} = React.useContext(AuthContext);
    const { logoutAzAd } = useMsal();

    const logout = () => { logoutAzAd();  }

    const itemClicked = () => () => {
        setIsUserInfoVisible(!isUserInfoVisible);
    }

    return (
            <UserInfo>
                <HeaderItem title={'Logged in user'} iconName={'user'} path={''} onItemClick={itemClicked()} />
            {
                isUserInfoVisible && 
                <div className='user-info-panel'>
                    <div className="top-wrapper">
                        <div>
                            <h3>{loggedInUser?.fullName}</h3>
                            <h4></h4>
                        </div>  
                    </div>
                    <div className="bottom-wrapper">
                    <button className="logout" onClick={logout} >
                        Sign out
                    </button>
                    </div>
                </div>
            }
    </UserInfo>
    );
}

export default UserInfoComponent;
