export class CountryCodeItem {
    constructor(
        landnr: string,
        land: string,
        alpha2Code: string,
    ) {
        this.landnr = landnr;
        this.land = land;
        this.alpha2Code = alpha2Code;
    }
    landnr: string
    land: string
    alpha2Code: string
}
