import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, Label, Wrapper } from './HeaderItem.styles';

type Props = {
    path: string,
    title: string,
    iconName: IconProp,
    onItemClick: () => void
}


const HeaderItem: React.FC<Props> = ( props ) => {
    const location = useLocation();

    return (
        <Wrapper onClick={() => {props.onItemClick()}}>
            <Icon>
                <FontAwesomeIcon icon={props.iconName} />
            </Icon>
            <Label>
                {props.title}
            </Label>
        </Wrapper>
    );
}
export default HeaderItem;
