import React, {useState} from 'react'
import {ContextProps} from '../types/ContextProps';
import { CountryData } from '../types/ViewModel/CountryData';
import { CustomerData } from '../types/ViewModel/CustomerData';
import { InvoiceData } from '../types/ViewModel/InvoiceData';

type EconomyDataContextType = {
    isLoadingCustomerData: boolean,
    isLoadingInvoiceData: boolean,
    isLoadingCountryData: boolean,
    isCreatingPdf: boolean,
    isCreatingExcel: boolean,
    isRetrievingPdfFromVisma: boolean,
    invoiceData: InvoiceData | null,
    updatedInvoiceData: InvoiceData | null,
    customerData: CustomerData | null,
    updatedCustomerData: CustomerData | null,
    tokenCheckDone: boolean,
    hasToken: boolean,
    countryData: CountryData | null,
    errorMessage: string | null,
    doTokenCheck: boolean,
    isSavingToken: boolean,
    tokenIsSavedSuccessfully: boolean,
    setIsLoadingCustomerData: (isLoading: boolean) => void,
    setIsLoadingInvoiceData: (isLoading: boolean) => void,
    setIsLoadingCountryData: (isLoading: boolean) => void,
    setIsCreatingPdf: (isLoading: boolean) => void,
    setIsCreatingExcel: (isLoading: boolean) => void,
    setIsRetrievingPdfFromVisma: (isLoading: boolean) => void,
    setInvoiceData:  (invData: InvoiceData | null) => void,
    setUpdatedInvoiceData:  (invData: InvoiceData) => void,
    setCustomerData:  (custData: CustomerData) => void,
    setUpdatedCustomerData:  (invData: CustomerData) => void,
    setTokenCheckDone: (isChecking: boolean) => void,
    setHasToken: (isLoading: boolean) => void,
    setCountryData:  (countryData: CountryData) => void,
    setErrorMessage:  (msg: string | null) => void,
    setDoTokenCheck: (doCheck: boolean) => void,
    setIsSavingToken: (isSaving: boolean) => void,
    setTokenIsSavedSuccessfully: (isSaved: boolean) => void,
}

export const EconomyDataContext = React.createContext<EconomyDataContextType | null>(null);

export const EconomyDataProvider = ({ children }: ContextProps) => {
    const [isLoadingCustomerData, setIsLoadingCustomerData] = useState<boolean>(false);
    const [isLoadingInvoiceData, setIsLoadingInvoiceData] = useState<boolean>(false);
    const [isLoadingCountryData, setIsLoadingCountryData] = useState<boolean>(false);
    const [isCreatingPdf, setIsCreatingPdf] = useState<boolean>(false);
    const [isCreatingExcel, setIsCreatingExcel] = useState<boolean>(false);
    const [isRetrievingPdfFromVisma, setIsRetrievingPdfFromVisma] = useState<boolean>(false);
    const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null);
    const [updatedInvoiceData, setUpdatedInvoiceData] = useState<InvoiceData | null>(null);
    const [customerData, setCustomerData] = useState<CustomerData | null>(null);
    const [updatedCustomerData, setUpdatedCustomerData] = useState<CustomerData | null>(null);
    const [tokenCheckDone, setTokenCheckDone] = useState<boolean>(false);
    const [hasToken, setHasToken] = useState<boolean>(false);
    const [countryData, setCountryData] = useState<CountryData | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [doTokenCheck, setDoTokenCheck] = useState<boolean>(true);
    const [isSavingToken, setIsSavingToken] = useState<boolean>(false);
    const [tokenIsSavedSuccessfully, setTokenIsSavedSuccessfully] = useState<boolean>(false);

    return <EconomyDataContext.Provider value={{
        isLoadingCustomerData,
        setIsLoadingCustomerData,
        isLoadingInvoiceData,
        setIsLoadingInvoiceData,
        isLoadingCountryData,
        setIsLoadingCountryData,
        isCreatingPdf,
        setIsCreatingPdf,
        isCreatingExcel,
        setIsCreatingExcel,
        isRetrievingPdfFromVisma,
        setIsRetrievingPdfFromVisma,
        invoiceData,
        setInvoiceData,
        updatedInvoiceData,
        setUpdatedInvoiceData,
        customerData,
        setCustomerData,
        updatedCustomerData,
        setUpdatedCustomerData,
        tokenCheckDone,
        setTokenCheckDone,
        hasToken,
        setHasToken,
        countryData,
        setCountryData,
        errorMessage,
        setErrorMessage,
        doTokenCheck,
        setDoTokenCheck,
        isSavingToken,
        setIsSavingToken,
        tokenIsSavedSuccessfully,
        setTokenIsSavedSuccessfully
    }}>
        {children}
    </EconomyDataContext.Provider >
};
