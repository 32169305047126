import { Console } from 'console';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Wrapper, BoxWrapper } from './TokenRetrievel.styles';
import usePostToken from '../../api/usePostToken';
import { EconomyDataContext } from '../../context/EconomyDataContext';
import { AuthContext } from '../../context/AuthContext';

type Props = {
  title: string
}

const TokenRetrievelComponent: React.FC<Props> = ({title}: Props) => {
  const location = useLocation();
  const [hasReceivedToken, setHasReceivedToken] = useState<boolean>(false);
  const [isRedirected, setIsRedirected] = useState<boolean>(false);
  const { saveToken } = usePostToken();
  const {
    isSavingToken,
    setIsSavingToken,
    tokenIsSavedSuccessfully
} = useContext(EconomyDataContext)!;
const { loggedInUser } = React.useContext(AuthContext);

  useEffect(() => {
    if (location && loggedInUser && !isSavingToken) {
      let query = new URLSearchParams(window.location.search);
      const token = query.get('token');
      if (token) {
        setIsSavingToken(true);
        saveToken(token);
        console.log('Token:' + token);
        setHasReceivedToken(true);
      }
      setIsRedirected(true);
    }
  }, [location, loggedInUser]);

  const handleClick = () => {
    window.open("about:blank", "_self");
    window.close();
  }

  const getText = (): string => {
    if (!hasReceivedToken) return 'Token parameter is missing. Please contact technical support.';
    if (tokenIsSavedSuccessfully) return 'Token saved successfully. Please close the window.';
    return 'Token not saved successfully. Please contact technical support.';
  }

  return (
    <>
      {isRedirected && !isSavingToken &&
        <Wrapper>
          <BoxWrapper>
            <div>
                <p>
                    {getText()}
                </p>
                <button onClick={handleClick}>Close window</button>
            </div>
          </BoxWrapper>
        </Wrapper>
    }
    </>
  );
}
export default TokenRetrievelComponent;

