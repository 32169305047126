import styled, { css } from 'styled-components';
import { Invoice } from '../../types/ViewModel/Invoice';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    margin: 5px;
    //position: relative;
    border-top: 1px solid #dde5cc;
    border-left: 1px solid #dde5cc;
    border-bottom: 1px solid #dde5cc;
    border-right: 1px solid #dde5cc;
`;

export const MainListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

export const ListHeadingWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 35px;
    width: 100%;
    background: #d9b3ff;
    border-bottom: 1px solid #dde5cc;
`;

export const ListOuterWrapper = styled.div`
height: 100%;
overflow: hidden;

    display: flex;
    flex-direction: column;
    width: 100%;
    // overflow-y: auto;
    // overflow-x: hidden;
    // height: 36%;
    position: relative;

    .rotation-tree-list{
        height: calc(100% - 50px);
        overflow-y: auto;
        max-height: 100%;
    }    
`;

export interface IListProps {
    isColor?: boolean;
}

export const ListWrapper = styled.div<IListProps>`
    display: flex;
    width: 100%;
    height: 25px;
    position: relative;
  
    ${({isColor }) =>
    isColor &&
    css`
        background: #eff3f7;
   `}
`;

export interface IValProps {
    isNegative?: boolean;
}

export const CheckBoxWrapper = styled.div`
    .chkbox {
        width: 20px;
        height: 20px;
    }
`;

export interface IButtonProps {
    isSelected?: boolean;
    altColor?: boolean;
    onClick?: (item: Invoice) => void;
}

export const GetButton = styled.button<IButtonProps>`
//   font-size: 0.9rem;
//   color: #04265a;
//   background: #fff;
//   border: none;
   cursor: pointer;
   margin-bottom: 3px;2
//   padding: 3px 10px;
//   margin: 4px 6px 0 5px;
  
  ${({isSelected }) =>
    isSelected &&
    css`
      color: #04265a;
      background: #fff;
      border: 1px solid #ccc;
   `}

   .fa-file {
       border: none;
   }
`;

export const ItemWrapper1 = styled.div`
    display: flex;
    width: 5%;
    height: 100%;
    text-align: left;
    position: relative;
    margin-left: 10px;
    margin-top: 4px;
`;

export const ItemWrapper2 = styled.div`
    display: flex;
    width: 6%;
    height: 100%;
    text-align: left;
    position: relative;
    margin-top: 4px;
`;

export const ItemWrapper3 = styled.div`
    display: flex;
    width: 6%;
    height: 100%;
    text-align: left;
    position: relative;
    margin-top: 4px;
`;

export const ItemWrapper4 = styled.div`
    display: flex;
    width: 6%;
    height: 100%;
    text-align: left;
    position: relative;
    margin-top: 4px;
`;

export const ItemWrapper5 = styled.div`
    display: flex;
    width: 23%;
    height: 100%;
    text-align: left;
    position: relative;
    margin-top: 4px;
`;

export const ItemWrapper6 = styled.div`
    display: flex;
    width: 8%;
    height: 100%;
    text-align: left;
    position: relative;
    margin-top: 4px;
`;

export const HeadingWrapper7 = styled.div`
    display: flex;
    flex-direction: row;
    width: 8%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 5px;
`;

export const NumberWrapper = styled.div<IValProps>`
    display: flex;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
     
    ${({isNegative }) =>
    isNegative &&
    css`
        background: #ff6666;
   `}
`;

export const ItemWrapper7 = styled.div`
    display: flex;
    flex-direction: row;
    width: 8%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const HeadingWrapper8 = styled.div`
    display: flex;
    flex-direction: row;
    width: 8%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 5px;
`;

export const ItemWrapper8 = styled.div`
    display: flex;
    flex-direction: row;
    width: 8%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const HeadingWrapper9 = styled.div`
    display: flex;
    flex-direction: row;
    width: 8%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 5px;
`;

export const ItemWrapper9 = styled.div`
    display: flex;
    flex-direction: row;
    width: 8%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const HeadingWrapper10 = styled.div`
    display: flex;
    flex-direction: row;
    width: 8%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 5px;
`;

export const ItemWrapper10 = styled.div`
    display: flex;
    flex-direction: row;
    width: 8%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
`;

export const HeadingWrapper11 = styled.div`
    display: flex;
    flex-direction: row;
    width: 8%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 5px;
    padding-right: 25px;
`;

export const ItemWrapper11 = styled.div`
    display: flex;
    flex-direction: row;
    width: 8%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 15px;
`;

export const HeadingWrapper12 = styled.div`
    display: flex;
    flex-direction: row;
    width: 6%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 5px;
    padding-right: 35px;
`;

export const ItemWrapper12 = styled.div`
    display: flex;
    flex-direction: row;
    width: 6%;
    height: 100%;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 15px;
`;
