import React, {useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import getContext from '../context/AppSettingsContext';
import { EconomyDataContext } from '../context/EconomyDataContext';
import useApi from './useApi';
import { AuthContext } from '../context/AuthContext';


export default () => {
    const history = useHistory();
    const appSettings = useContext(getContext());
    const { state, getApiData } = useApi();
    const endpoint = 'HasToken';
    const {
        setTokenCheckDone,
        setHasToken,
        setErrorMessage
    } = useContext(EconomyDataContext)!;
    const { loggedInUser } = React.useContext(AuthContext);
        
    const getHasToken = async () => {
        const url = appSettings.api + endpoint;
        var response = await axios
            .get(url)
            .then(resp => {
                var x = resp.status;
                console.log('Response:' + resp);
                setHasToken(resp.data);
                setTokenCheckDone(true);
            })
            .catch(error => {
                setErrorMessage(error.toString());
                history.push("/error");
            });   
    };

//     const getInvoices = (fromDate: Date, toDate: Date) => {
//         getApiData(endpoint, '');
//     };

//     useEffect(() => {
//         setIsCheckingToken(state.isLoading);
//     if (state.status === 'success'){
//         const invoiceDataBM = state.result as unknown as InvoiceDataBM;
//         const invoiceData = invoiceDataFactory.newInvoiceData(invoiceDataBM);
//         setInvoiceData(invoiceData);
//     }
// }, [state]);

    return { getHasToken };
}
