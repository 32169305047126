import React, {useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import axios, { AxiosRequestConfig } from 'axios';
import getContext from '../context/AppSettingsContext';
import { EconomyDataContext } from '../context/EconomyDataContext';
import * as FileSaver from "file-saver";
import moment from "moment";
import { time } from 'console';
import { AuthContext } from '../context/AuthContext';

export default () => {
    const history = useHistory();
    const appSettings = useContext(getContext());
    const endpoint = 'HasToken';
    const {
        setIsCreatingPdf,
        setIsCreatingExcel
    } = useContext(EconomyDataContext)!;
    const { loggedInUser } = React.useContext(AuthContext);

    const createQueryParam = (fromDate: string, toDate: string, exIdList: string): string => {
        return "?fromDate=" + fromDate + "&toDate=" + toDate + "&exIdList=" + exIdList;
    }
    
    const prettifyDateTime = (date: Date) => date ? moment(date).format('DD.MM.YYYY HH.mm.ss') : '';

    const getExcelFilename = (filename: string, timestamp: Date): string => {
        return filename + ' ' + prettifyDateTime(timestamp) + '.xlsx';
    }

    const getPdfFilename = (filename: string, timestamp: Date): string => {
        return filename + ' ' + prettifyDateTime(timestamp) + '.pdf';
    }

    const saveExcel = (filename: string, data: any) => {
        var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, filename);
    }

    const savePdf = (filename: string, data: any) => {
        var blob = new Blob([data], { type: 'application/pdf' });
        FileSaver.saveAs(blob, filename);
    }

    const dateToQueryParam = (date: Date): string => {
        return moment(date).format('YYYY-MM-DD')
    }

    const getExcel = async (fromDate: Date, toDate: Date, exIdList: string, timestamp: Date) => {
        const url = appSettings.api + 'GetInvoiceExcel' + createQueryParam(dateToQueryParam(fromDate), dateToQueryParam(toDate), exIdList);
        const excelFilename = getExcelFilename('Fakturajournal', timestamp);
        const headers = {'Content-Type': 'blob'};
        const config: AxiosRequestConfig = {responseType: 'arraybuffer', headers};
        var response = await axios
            .get(url, config)
            .then(resp => {
                console.log('Response:' + resp);
                return resp;
            })
            .catch(error => {
            });   
            if (!response) {
            } else if (response.status !== 200) {
                setIsCreatingExcel(false);
                if (response.data) {
                } else {
                }
            } else {
                saveExcel(excelFilename, response.data);
                setIsCreatingExcel(false);
            }
    };
    
    const getPdf = async (fromDate: Date, toDate: Date, exIdList: string, timestamp: Date) => {
        const url = appSettings.api + 'GetInvoicePdf' + createQueryParam(dateToQueryParam(fromDate), dateToQueryParam(toDate), exIdList);
        const pdfFilename = getPdfFilename('Fakturajournal', timestamp);
        const headers = {'Content-Type': 'blob'};
        const config: AxiosRequestConfig = {responseType: 'arraybuffer', headers};
        var response = await axios
            .get(url, config)
            .then(resp => {
                console.log('Response:' + resp);
                return resp;
            })
            .catch(error => {
            });   
            if (!response) {
            } else if (response.status !== 200) {
                setIsCreatingPdf(false);
                if (response.data) {
                } else {
                }
            } else {
                savePdf(pdfFilename, response.data);
                setIsCreatingPdf(false);
            }
    };

    return { getExcel, getPdf };
}
