import { InvoiceBM } from '../types/BusinessModel/InvoiceBM';
import { Invoice } from '../types/ViewModel/Invoice';
import { InvCustomerFactory } from './InvCustomerFactory';
import { PdfFactory } from './PdfFactory';

export class InvoiceFactory {
    private invCustomerFactory = new InvCustomerFactory();
    private pdfFactory = new PdfFactory();

    constructor() {}

    public newInvoiceArray(invoiceBMArray: InvoiceBM[]): Invoice[] {
        var invoiceArray: Invoice[] = [];
        if (invoiceBMArray && invoiceBMArray.length > 0) {
            invoiceBMArray.forEach(cust => {
                invoiceArray.push(this.newInvoice(cust));
            });
        }
        return invoiceArray;
    }

    public newInvoiceArrayFromCode(invoiceArray: Invoice[]): Invoice[] {
        const invArray: Invoice[] = [];
        if (invoiceArray && invoiceArray.length > 0) {
            invoiceArray.forEach(inv => {
                invArray.push(this.newInvoiceFromCode(inv));
            });
        }
        return invArray;
    }

    public newInvoice(invoiceBM: InvoiceBM): Invoice {
        return new Invoice(
            invoiceBM.bookedInvoiceNumber,
            invoiceBM.date,
            invoiceBM.currency,
            invoiceBM.exchangeRate,
            invoiceBM.netAmount,
            invoiceBM.netAmountInBaseCurrency,
            invoiceBM.grossAmount,
            invoiceBM.grossAmountInBaseCurrency,
            invoiceBM.vatAmount,
            invoiceBM.roundingAmount,
            invoiceBM.remainder,
            invoiceBM.remainderInBaseCurrency,
            invoiceBM.dueDate,
            // paymentTerms: PaymentTerms: number,
            this.invCustomerFactory.newInvCustomer(invoiceBM.customer),
            // recipient: Recipient,
            // delivery: Delivery,
            // references: References,
            // layout: Layout,
            this.pdfFactory.newPdf(invoiceBM.pdf),
            invoiceBM.sent,
            invoiceBM.self,
            true
        )
    }

    public newInvoiceFromCode(invoice: Invoice): Invoice {
        return new Invoice(
            invoice.bookedInvoiceNumber,
            invoice.date,
            invoice.currency,
            invoice.exchangeRate,
            invoice.netAmount,
            invoice.netAmountInBaseCurrency,
            invoice.grossAmount,
            invoice.grossAmountInBaseCurrency,
            invoice.vatAmount,
            invoice.roundingAmount,
            invoice.remainder,
            invoice.remainderInBaseCurrency,
            invoice.dueDate,
            // paymentTerms: PaymentTerms: number,
            this.invCustomerFactory.newInvCustomerFromCode(invoice.customer),
            // recipient: Recipient,
            // delivery: Delivery, 
            // references: References,
            // layout: Layout,
            this.pdfFactory.newPdfFromCode(invoice.pdf),
            invoice.sent,
            invoice.self,
            invoice.checked
        )
    }
}
