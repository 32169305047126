import { InvoiceDataBM } from '../types/BusinessModel/InvoiceDataBM';
import { InvoiceData } from '../types/ViewModel/InvoiceData';
import { InvoiceFactory } from './InvoiceFactory';

export class InvoiceDataFactory {
  private invoiceFactory = new InvoiceFactory();

  constructor() {}

  public newInvoiceData(invoiceDataBM: InvoiceDataBM): InvoiceData {
      return new InvoiceData(false, this.invoiceFactory.newInvoiceArray(invoiceDataBM.collection));
  }

  public newInvoiceDataFromCode(invoiceData: InvoiceData): InvoiceData {
    return new InvoiceData(invoiceData.dataReceived, this.invoiceFactory.newInvoiceArrayFromCode(invoiceData.invoiceArray));
  }

  public newEmptyInvoiceDataFromCode(): InvoiceData {
    return new InvoiceData(false, []);
  }
}
