import { Customer } from "./Customer";

export class CustomerData {
    constructor(
        customerArray: Customer[]
    ) {
        this.customerArray = customerArray;
    }
    customerArray: Customer[];
}
