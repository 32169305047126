import React, { useContext, useState } from 'react'
import { Wrapper, BoxWrapper } from './Authorize.styles';
import { useHistory } from 'react-router-dom';
import useGetHasToken from '../../api/useGetHasToken';
import { EconomyDataContext } from '../../context/EconomyDataContext';

export default () => {
  const history = useHistory();
  const [tokenBtnClicked, setTokenBtnClicked] = useState<boolean>(false);
  const { getHasToken } = useGetHasToken();
  const {
    setDoTokenCheck
} = useContext(EconomyDataContext)!;

  const openMyWindow = () => {
    setTokenBtnClicked(true);
    window.open("https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=MUJEILlBU8Mb714KYpFuhUdZMmxVCs3SdM0kH3lvDU41&redirectUrl=https%3A%2F%2Feconomicexporter.head.energy%2Ftokenretrievel", "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30")
  }

   const goToMain = () => {
    setDoTokenCheck(true);
    getHasToken();
    //history.push("/");
   }

   //target="blank" and rel="noopener noreferrer"
   //Old:https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=2VMzuKiYAdbRBmhfUBQzSVrYaZVF6CZuJDYtAriCBz41
   //https://secure.e-conomic.com/secure/api1/requestaccess.aspx?appPublicToken=MUJEILlBU8Mb714KYpFuhUdZMmxVCs3SdM0kH3lvDU41&redirectUrl=http%3A%2F%2Flocalhost%3A3000%2Ftokenretrievel

   return (
    <>
      <Wrapper>
        {!tokenBtnClicked &&
          <BoxWrapper>
          <div>
              <p>
                  Please click the button below to create a new e-conomic token.
                  (make sure you are logged in to Visma e-conomic)
              </p>
              <button onClick={openMyWindow}>Create token</button>
          </div>
        </BoxWrapper>
      }
      {tokenBtnClicked &&
        <BoxWrapper>
          <div>
              <p>
                  After retrieving the token click the button to go to main window
              </p>
              <button onClick={goToMain}>Go to main</button>
          </div>
        </BoxWrapper>
      }
      </Wrapper>
    </>
  );
}
