import { CountryCodeItemBM } from "../BusinessModel/CountryCodeItemBM";
import { CurrencyCodeItemBM } from "../BusinessModel/CurrencyCodeItemBM";

export class Pdf {
    constructor(
        download: string
    ) {
        this.download = download;
    }
    download: string;
}
