import styled from 'styled-components';

export const Wrapper = styled.div`
    grid-area: header-panel;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    box-shadow: 0 2px 6px 2px #ddd;
    margin: 0;
    margin-bottom: ${props => props.theme.constants.pageHeaderMargin};
    padding-right: 1rem;
    height: ${props => props.theme.constants.pageHeaderHeight};
    h1{
        font-size: 2rem;
        font-weight: 200;  
        margin: 0 0 0 20px;
        position: relative;
        .sub-header{
          position: absolute;
          top: -1px;
          right: -31px;
          font-size: 1rem;
        }
      }
`;

export const Title = styled.h1`
  text-transform: capitalize;
  padding: 10px 2rem 10px 0;
  font-size: 1.5rem;
  text-align: center;
  border: 1px #ddd;
  margin: 0 10px 0 0;
  font-size: 1.75rem;
  border-right-style: solid;
  font-weight: 100; 
`;

export const LeftContentWrapper = styled.div`
    flex: 0 0 40%;
    max-width: 40%;
    display: flex;
    align-items: center;
    padding: 0 0 0 15px;
    justify-content: flex-start;
`;

export const RightContentWrapper = styled.div`
    font-size: 0.8rem;
    font-weight: 400;
    color: #04265a;
    display: flex;
    flex: 0 0 60%;
    max-width: 60%;
    justify-content: flex-end;
    align-items: center;

    label {
      margin: 0 2px 0 20px;
      font-weight: 500;
    }

    table {
      float: right;
      margin: 5px 10px;

      td {
        padding: 0px 15px;
        margin: 0;
        line-height: 20px;
        text-align: center;
        border: 1px #b0b7c1;
        border-right-style: solid;
        border-left-style: solid;
      }
    }

    .heading td {
      font-weight: 500;
    }

    .app-menu{
      button {
        border: none;
        cursor: pointer;
        outline: 0;
        vertical-align: middle;
        color: #004781;
        border-radius: 0;
        font-size: 12px;
        text-align: center;
        margin: 0 10px 0 0;
        background: none;
        padding: .25em .5em;
          i {
            display: block;
            color: #004781;
            cursor: pointer;
            outline: 0;
            padding: 0;
            text-align: center;
          }
          .icon-label {
            font-size: 11px;
            display: block;
            margin: 5px 0 0;
          }
      }
    }
`;

export const IconWrapper = styled.div`
  border-left: 1px solid #ddd;
  display: flex;
  height: 40px;
  padding: 0 10px;
  grid-gap: 10px;
  color: #009933;
`;

export const HeaderItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: baseline;

  img {
      margin-right: 2rem
  }
`;

