import { InvCustomer } from "./InvCustomer";
import { Pdf } from "./Pdf";

export class Invoice {
    constructor(
        bookedInvoiceNumber: number,
        date: Date,
        currency: string,
        exchangeRate: number,
        netAmount: number,
        netAmountInBaseCurrency: number,
        grossAmount: number,
        grossAmountInBaseCurrency: number,
        vatAmount: number,
        roundingAmount: number,
        remainder: number,
        remainderInBaseCurrency: number,
        dueDate: Date,
        // paymentTerms: PaymentTerms: number,
        customer: InvCustomer,
        // recipient: Recipient,
        // delivery: Delivery,
        // references: References,
        // layout: Layout,
        pdf: Pdf,
        sent: string,
        self: string,
        checked: boolean
    ) {
        this.bookedInvoiceNumber = bookedInvoiceNumber;
        this.date = date;
        this.currency = currency;
        this.exchangeRate = exchangeRate;
        this.netAmount = netAmount;
        this.netAmountInBaseCurrency = netAmountInBaseCurrency;
        this.grossAmount = grossAmount;
        this.grossAmountInBaseCurrency = grossAmountInBaseCurrency;
        this.vatAmount = vatAmount;
        this.roundingAmount = roundingAmount
        this.remainder = remainder;
        this.remainderInBaseCurrency = remainderInBaseCurrency;
        this.dueDate = dueDate;
        // this.paymentTerms: PaymentTerms: number;
        this.customer = customer;
        // this.recipient: Recipient;
        // this.delivery: Delivery;
        // this.references: References;
        // this.layout: Layout;
        this.pdf = pdf;
        this.sent = sent;
        this.self = self;
        this.checked = checked;
    }
    bookedInvoiceNumber: number;
    date: Date;
    currency: string;
    exchangeRate: number;
    netAmount: number;
    netAmountInBaseCurrency: number;
    grossAmount: number;
    grossAmountInBaseCurrency: number;
    vatAmount: number;
    roundingAmount: number;
    remainder: number;
    remainderInBaseCurrency: number;
    dueDate: Date;
    // paymentTerms: PaymentTerms: number;
    customer: InvCustomer;
    // recipient: Recipient;
    // delivery: Delivery;
    // references: References;
    // layout: Layout;
    pdf: Pdf;
    sent: string;
    self: string;
    checked: boolean;
}
