import styled from 'styled-components';

export const Wrapper = styled.div`
    cursor: pointer;
    &:hover{
        background: #f4f4f4;
    }
    .active-link {
        border-bottom: solid 1px;
    }
`;

export const Icon = styled.span`
    text-align: center;
    display: block;
    margin: 0 0 5px 0;
`;

export const Label = styled.span`
    text-align: center;
`;