import React, {useContext, useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom';
import { PageContentWrapper, TopWrapper, BottomWrapper } from './Main.styles';
import MainHeading from '../../components/main-heading/MainHeading';
import MainList from '../../components/main-list/MainList';
import { EconomyDataContext } from '../../context/EconomyDataContext';

type Props = {
    title: string
}

const MainComponent: React.FC<Props> = ({title}: Props) => {
    const {
        hasToken
    } = useContext(EconomyDataContext)!;
  
    return (
        <>
        {hasToken &&
            <PageContentWrapper>
                <TopWrapper>
                    <MainHeading></MainHeading>
                </TopWrapper>
                <BottomWrapper>
                    <MainList></MainList>
                </BottomWrapper>
            </PageContentWrapper>
        }
       </>
    );
}
export default MainComponent;
