import styled from 'styled-components';

export const Wrapper = styled.div`

    display: flex;
    flex-direction: column
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BoxWrapper = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 200px;
    //left: 200px;
    background: #fff;
    border-radius: 4px;
    padding: 10px 40px 40px 40px;
    width: 300px;
    h1 {
        font-weight: 200;
    }
    p {
        font-size: 17px;
        line-height: 1.5;
    }
    button {
        margin: 40px 0 0;
        background: #ff9966;
        padding: 15px 30px;
        border-radius: 4px;
        border: 0;
        color: black;
        font-size: 15px;
        outline: none;
        cursor: pointer;
    }
`;
