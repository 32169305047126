import React, {useState} from 'react'
import {ContextProps} from '../types/ContextProps';
import {AddDays} from "../utils/dateUtils";

type DateContextType = {
    manuallySetLocalTime: Date | null;
    portCash: any;
    setManuallySetLocalTime: (date: Date) => void,
    setPortCash: (delta: any) => void,
}

export const DateContext = React.createContext<DateContextType>(undefined!);

export const DateProvider = ({ children }: ContextProps) => {
    const [manuallySetLocalTime, setManuallySetLocalTime] = useState<Date | null>(null);
    var [portCash, setPortCash] = useState<any>({});

    return <DateContext.Provider value={{
        manuallySetLocalTime,
        setManuallySetLocalTime,
        portCash,
        setPortCash
    }}>
        {children}
    </DateContext.Provider >
};
