import { CountryCodeItemBM } from '../types/BusinessModel/CountryCodeItemBM';
import { CountryCodeItem } from '../types/ViewModel/CountryCodeItem';

export class CountryCodeItemFactory {
    constructor() {}

    public newCountryCodeItemArray(countryCodeItemBMArray: CountryCodeItemBM[]): CountryCodeItem[] {
        var array: CountryCodeItem[] = [];
        if (countryCodeItemBMArray && countryCodeItemBMArray.length > 0) {
            countryCodeItemBMArray.forEach(item => {
                array.push(this.newCountryCodeItem(item));
            });
        }
        return array;
    }

    public newCountryCodeItem(countryCodeItemBM: CountryCodeItemBM): CountryCodeItem {
        return new CountryCodeItem(countryCodeItemBM.landnr, countryCodeItemBM.land, countryCodeItemBM.alpha2Code);
    }
}
