import { Link } from "react-router-dom";
import { Wrapper, LogoWrapper, LeftContentWrapper, RightContentWrapper, 
         IconWrapper} from './PageHeader.styles';
import UserInfoComponent from './user-info/UserInfo';
import React, {useContext, useEffect, useState } from 'react';

export default () => {

    const applyScriptToPage = (src) => {
        let script = document.querySelector(`script[src="${src}"]`);
        if (!script) {
            script = document.createElement("script");
            script.innerHTML = src;
            document.body.appendChild(script);
        }
    }

    return (
        <>
            <Wrapper>
                <LeftContentWrapper>
                    <LogoWrapper>
                        <Link to="/">
                            <h1>e-conomic Exporter<span className="sub-header">Web</span></h1>
                        </Link>
                    </LogoWrapper>
                </LeftContentWrapper>
                <RightContentWrapper>
                    <IconWrapper>
                        <UserInfoComponent />
                    </IconWrapper>
                </RightContentWrapper>
            </Wrapper>
        </>
    );
}