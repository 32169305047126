import React, {useState} from 'react';
import {ContextProps} from "../types/ContextProps";

type AppSettingsStateContextType = {
    isGettingAppSettings: boolean,
    setGettingAppSettings: (setting: boolean) => void
}
export const AppSettingsStateContext = React.createContext<AppSettingsStateContextType | null>(null);

export const AppSettingsStateProvider = ({ children }: ContextProps) => {
    const [isGettingAppSettings, setGettingAppSettings] = useState<boolean>(true);

    return <AppSettingsStateContext.Provider value={{
        isGettingAppSettings,
        setGettingAppSettings
    }}>
        {children}
    </AppSettingsStateContext.Provider >
}
