export class User {
    constructor(
        roles: string[],
        isOnline: boolean,
        fullName: string,
        userName: string
    ) {
        this.roles = roles;
        this.isOnline = isOnline;
        this.fullName = fullName;
        this.userName = userName;
    }
    roles: string[];
    isOnline: boolean;
    fullName: string;
    userName: string;
}