import { CustomerBM } from '../types/BusinessModel/CustomerBM';
import { CustomerDataBM } from '../types/BusinessModel/CustomerDataBM';
import { InvCustomerBM } from '../types/BusinessModel/InvCustomerBM';
import { Customer } from '../types/ViewModel/Customer';
import { CustomerData } from '../types/ViewModel/CustomerData';
import { InvCustomer } from '../types/ViewModel/InvCustomer';

export class InvCustomerFactory {
    constructor() {}

    public newInvCustomer(invCust: InvCustomerBM): InvCustomer {
        return new InvCustomer(invCust.customerNumber, null, invCust.self);
    }

    public newInvCustomerFromCode(invCust: InvCustomer): InvCustomer {
        return new InvCustomer(invCust.customerNumber, invCust.name, invCust.self);
    }
}
