import { useContext, useReducer } from 'react';
import axios from 'axios';
import ApiReducer from '../api/ApiReducer';
import getContext from "../context/AppSettingsContext";

export default () => {
    const appSettings = useContext(getContext());
    let url = appSettings.api + 'IsAuthorizedWithCorrectUser';
    const isTokenAuthorizedOld = async () => {
        try {
            const response = await axios.get(url);
            if (response.status !== 200) {
                // Check if UnAuthorized
                if (response.status === 401) {
                    console.log('UnAuthorized');
                    return false;
                }
                console.log('Error:' + response.status + '(' + response.statusText + ')');
                // throw new Error(`${response.status} ${response.statusText}`);
            }
            return response.data;
        } catch (error) {
            var xx = error;
            console.log('Exception:' + error);
            // throw new Error(`${error}`);
        }
    };

    const isTokenAuthorized = async () => {
        return true;
        //TODO: review this and implement on API side if needed
        const response = await axios.get(url)
            .then((response) => {
                return response.data;
            })
            .catch(({response}) => {
                if (response.status === 401) {
                    return false;
                }
                throw new Error(`${response}`);
            })
        return response;
    }

    return { isTokenAuthorizedOld, isTokenAuthorized };
}
